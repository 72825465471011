import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page not found" description="This page was not found" />
    <div className="bg-background py-8">
      <div className="container sm:w:4/5 md:w-7/12 pt-10 bg-white shadow-lg">
        <div className="text-center my-4">
          <h1 className="font-bold text-3xl">This page was not found</h1>
          <h2 className="text-xl pt-6 pb-12 px-8">
            Return to the{' '}
            <Link to="/" className="text-primary">
              homepage
            </Link>
          </h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
